<template>
    <div class="en-classify-all">
        <!-- 头部导航 -->
        <div class="header">
            <leftBar />
            <span>Categories</span>
            <router-link to="/en/cart" >
                <img src="@/assets/classify/icon_shop bag@2x.png" alt="img">
            </router-link>
        </div>

        <div class="classify-main" ref="scroll">
            <div class="classify-main-item" v-for="(item, index) in typeList" :key="index"
            @click="$router.push({path: '/en/classify_two', query: {title: item.title_en, id: item.id}})">
                <img :src="item.pic_url_en" alt="">
                <div class="user-info">
                    <h3>{{item.title_en}}</h3>
                    <p>{{item.num}} items</p>
                    <img src="@/assets/classify/icon_more@2x.png" alt="">
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="less">
.en-classify-all {
    width: 100%;
    height: 100%;
    .header {
      width: 100%;
      height: 12vw;
      padding: 0  16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #fff;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 13;
      &>img {
        width: 5.86667vw;
        height: 5.86667vw;
        transition: all 0.3s;
      }
      &>span {
        font-size: 4.8vw;
        font-weight: 400;
        color: #333333;
        letter-spacing: 1px;
      }
      &>a {
        position: relative;
        &>img {
          width: 5.86667vw;
          height: 5.86667vw;
        }
      }
      
    }
    .classify-main {padding-top:12vw;overflow-y: auto;height:100%;box-sizing: border-box;
        &-item{width:100%;display: flex;border-top:1px solid #f1f1f1;padding:2vw 0 2vw 3vw;
            >img{display: block;width:15vw;height:15vw;object-fit: cover;margin:2.5vw;background-color: #eeeeee;border-radius: 5px;}
            .user-info{flex: 1;height:100%;position: relative;
                h3{font-size: 16px;color:#444;font-weight: 600;line-height: 8vw;margin-top:3.4vw;}
                p{
                    font-size: 3.2vw;
                    font-weight: 300;
                    color: #666666;
                }
                img{width:5vw;height:5vw;display: inline-block;vertical-align: middle;position: absolute;top:8vw;right:5vw;}
            }
        }
    }
}
</style>

<script>
import leftBar      from '@/components/en/left-bar.vue'

import { getTypeList } from '@/api/en/classify.js'
import MixinScroll from '@/untils/js/mixin-scroll.js'
export default {
    mixins: [ MixinScroll ],
    components: { leftBar },
    data() {
        return {
            typeList: []
        };
    },
    methods: {
        getTypeListHandle() {
            // 获取一级分类
            getTypeList().then(res => {
                if(res) {
                if (this.$store.state.isMiniProgram) {
                    let Data = res.data.filter(item => {
                         return item.id != 10 && item.id != 12 && item.id != 14 && item.id != 16
                     });
                     this.typeList = Data
                }else{
                    this.typeList = res.data
                }
                }
            })
        }
    },
    created() {
        this.getTypeListHandle()
    }
};
</script>